<template>
  <!--添加/修改地址弹窗  -->
  <el-dialog title="会员选择" :visible.sync="show">
    <el-form ref="addressForm" :rules="rules" :model="addressForm" label-width="80px">
      <el-form-item label="收货人" prop="consignee">
        <el-input v-model="addressForm.consignee"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="addressForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="所在地区" prop="region">
        <el-cascader :options="options" v-model="addressForm.region" />
      </el-form-item>
      <el-form-item label="详情地址" prop="location">
        <el-input v-model="addressForm.location"></el-input>
      </el-form-item>
      <el-form-item label="楼层">
        <el-select v-model="addressForm.floor">
          <el-option label="有电梯" value="0"></el-option>
          <el-option label="1楼" value="1"></el-option>
          <el-option label="2楼" value="2"></el-option>
          <el-option label="3楼" value="3"></el-option>
          <el-option label="4楼" value="4"></el-option>
          <el-option label="5楼" value="5"></el-option>
          <el-option label="6楼" value="6"></el-option>
          <el-option label="7楼" value="7"></el-option>
          <el-option label="8楼" value="8"></el-option>
          <el-option label="9楼" value="9"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否默认">
        <el-switch active-value="1" inactive-value="0" v-model="addressForm.default"></el-switch>
      </el-form-item>
      <el-form-item label="配送站点">
        <el-select v-model="addressForm.shop_id">
          <el-option label="天下行水超市【总店】" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { pcaTextArr } from "element-china-area-data";
import { updateAddress } from "@/api/salesManagement/manualDeclaration.js";
export default {
  data() {
    return {
      // 表格数据
      addressForm: {},
      // 表单验证
      rules: {
        consignee: [
          { required: true, message: "请输入收货人", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        region: [
          { required: true, message: "请选择所在地区", trigger: "change" },
        ],
        location: [
          { required: true, message: "请输入详情地址", trigger: "blur" },
        ],
      },
      // 显示
      show: false,
      //地区选项
      options: pcaTextArr,
      // 父组件的row
      row: ""
    };
  },
  methods: {
    // 添加地址按钮
    addDialog(data) {
      this.reset();
      this.addressForm.member_id = data.member_id;
      this.addressForm.phone = data.bind_phone;
      this.addressForm.consignee = data.member_name;
      this.show = true;
    },
    // 修改地址按钮
    updateDialog(data) {
      console.log(data);
      this.reset();
      // console.log("form", this.addressForm);
      Object.keys(this.addressForm).forEach((key) => {
        this.addressForm[key] = data[key];
      });
      this.addressForm.region = this.addressForm.region.split(" ");
      this.addressForm.default = this.addressForm.default.toString();
      this.addressForm.floor = this.addressForm.floor.toString();
      this.addressForm.shop_id = this.addressForm.shop_id.toString();
      // console.log("form2",this.addressForm)

      this.show = true;
    },
    // 提交数据
    onSubmit() {
      this.$refs["addressForm"].validate((valid) => {
        if (valid) {
          this.addressForm.region = this.addressForm.region.join(" ");
          updateAddress(this.addressForm).then((res) => {
            console.log("res", res);
            if (res.status == 200) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.$emit('memberSubmit', this.row)
              this.show = false;
            } else {
              this.$message({
                message: res.message,
                type: 'warning',
              });
            }
          });
        }
      });

    },
    // 表单清空
    reset() {
      this.addressForm = {
        id: "",
        member_id: "",
        consignee: "",
        phone: "",
        region: "",
        location: "",
        floor: "",
        default: "",
        shop_id: "",
      };
      if (this.$refs["addressForm"]) {
        this.$refs["addressForm"].resetFields();
      }
    },
  },
};
</script>
